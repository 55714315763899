import React, { Component } from 'react'
import Input from '../Input/Input'
import Button from '../Button/Button'
import classes from './InquiryForm.module.css'
import { FaRocket, FaPlane } from 'react-icons/fa'; // Import the Font Awesome rocket icon
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
import clickfreight from '../../../images/click.png'
import Select from 'react-select';






export default class InquiryForm extends Component {

    formRef = React.createRef(); // Initialize the ref directly


    state = {

        selectedOption: { value: 'international', label: 'International(SI)' }, // Default value
        selectedCargo: '', // State to store the selected cargo type



        selectedArray: 'seaElementsArray',
        productValue: '',

        seaWorkflow: {
            TransportationBy: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Full Container Load FCL', displayValue: 'Full Container Load FCL' },
                        { value: 'Less Container Load LCL', displayValue: 'Less Container Load LCL' },
                        { value: 'Bulk', displayValue: 'Bulk' },

                        // Add more options as needed
                    ],
                    placeholder: 'Select Type',
                },
                value: 'Full Container Load FCL',
                currentObject: 'seaWorkflow',
                name: 'TransportationBy', // Set the name attribute
                label: 'Transportation By *',


                FCL: {
                    ContainerType: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                { value: "20' Standard", displayValue: "20' Standard" }, { value: "40' Standard", displayValue: "40' Standard" }, { value: "40' High Cube", displayValue: "40' High Cube" }, { value: "45' High Cube", displayValue: "45' High Cube" }, { value: "20' Refrigerated", displayValue: "20' Refrigerated" }, { value: "40' Refrigerated", displayValue: "40' Refrigerated" }, { value: "20' Open Top", displayValue: "20' Open Top" }, { value: "40' Open Top", displayValue: "40' Open Top" }, { value: "20' Flatrack", displayValue: "20' Flatrack" }, { value: "40' Flatrack", displayValue: "40' Flatrack" }, { value: "20' Flatrack Collapsible", displayValue: "20' Flatrack Collapsible" }, { value: "40' Flatrack Collapsible", displayValue: "40' Flatrack Collapsible" }, { value: "20' High Cube", displayValue: "20' High Cube" }, { value: "20' Pallet Wide", displayValue: "20' Pallet Wide" }, { value: "20' High Cube Pallet Wide", displayValue: "20' High Cube Pallet Wide" }, { value: "40' Pallet Wide", displayValue: "40' Pallet Wide" }, { value: "40' High Cube Pallet Wide", displayValue: "40' High Cube Pallet Wide" }, { value: "45' High Cube Pallet Wide", displayValue: "45' High Cube Pallet Wide" }, { value: "10' Standard", displayValue: "10' Standard" }, { value: "20' Bulk", displayValue: "20' Bulk" }, { value: "20' Tank", displayValue: "20' Tank" }, { value: "20' Platform", displayValue: "20' Platform" }, { value: "40' Platform", displayValue: "40' Platform" }, { value: "48' High Cube", displayValue: "48' High Cube" }, { value: "53' High Cube", displayValue: "53' High Cube" }
                                // Add more options as needed
                            ],
                            placeholder: 'Container Type',
                        },
                        value: '',
                        label: 'Container Type *',
                        name: 'container-type'

                    },
                    QuantityOfContainers: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Quantity of Containers'
                        },
                        value: '',
                        label: 'Quantity Of Containers *',
                        name: 'quantity-of-containers'

                    },
                    Weight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Weight'
                        },
                        value: '',
                        label: 'Weight *',
                        name: 'weight'

                    },
                },

                LCL: {
                    Dimensions: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Dimensions'
                        },
                        value: '',
                        label: 'Dimensions *',
                        name: 'Dimensions'

                    },
                    Quantity: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Quantity'
                        },
                        value: '',
                        label: 'Quantity *',
                        name: 'Quantity'

                    },
                    GrossWeight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Gross Weight'
                        },
                        value: '',
                        label: 'GrossWeight *',
                        name: 'GrossWeight'

                    },
                    // Weight: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Weight'
                    //     },
                    //     value: ''
                    // },
                    // Volume: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Volume'
                    //     },
                    //     value: ''
                    // }
                },
                // Additional fields specific to Bulk
                Bulk: {
                    ShipType: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                { value: 'Bulk carrier', displayValue: 'Bulk carrier' }, { value: 'Containership', displayValue: 'Containership' }, { value: 'General cargo vessel', displayValue: 'General cargo vessel' }, { value: 'Product tanker', displayValue: 'Product tanker' }, { value: 'Crude carrier', displayValue: 'Crude carrier' }, { value: 'Asphalt carrier', displayValue: 'Asphalt carrier' }, { value: 'Chemical tanker', displayValue: 'Chemical tanker' }, { value: 'Gas carrier', displayValue: 'Gas carrier' }, { value: 'Heavy-lift', displayValue: 'Heavy-lift' }, { value: 'Livestock', displayValue: 'Livestock' }, { value: 'Refrigerated', displayValue: 'Refrigerated' }, { value: 'Ro/Ro', displayValue: 'Ro/Ro' }, { value: 'Wood chip', displayValue: 'Wood chip' }
                                // Add more options as needed
                            ],
                            placeholder: 'Select Ship Type'
                        },
                        value: '',
                        label: 'ShipType *',
                        name: 'ShipType'

                    },
                    GrossWeight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Gross Weight'
                        },
                        value: '',
                        label: 'GrossWeight *',
                        name: 'GrossWeight'

                    },
                    LoadingRate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Loading Rate'
                        },
                        value: '',
                        label: 'LoadingRate *',
                        name: 'LoadingRate'

                    },
                    DischargingRate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Discharging Rate'
                        },
                        value: '',
                        label: 'DischargingRate *',
                        name: 'DischargingRate'

                    }
                },
            },


            from: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }
                        // Add more options as needed
                    ],
                    placeholder: 'Select From',
                },
                value: 'select',
                currentObject: 'seaWorkflow',
                name: 'from',
                label: 'From *',



            },
            to: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select To',

                },
                currentObject: 'seaWorkflow',

                value: '',
                name: 'to',
                label: 'To *',


            },
            readyToLoad: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Ready To Load',

                },
                currentObject: 'seaWorkflow',
                value: '',
                name: 'ready-to-load',
                label: 'ready To Load *',


            },
            additionalInformation: {
                elementType: 'textarea',
                elementConfig: {
                    placeholder: 'Additional Information',

                },
                currentObject: 'seaWorkflow',
                value: '',
                name: 'message',
                label: 'additional Information *',


            },
            associatedServices: {
                elementType: 'checkboxM',
                elementConfig: {
                    type: 'checkbox',
                    options: [
                        { value: 'insurance', label: 'Insurance' },
                        { value: 'certification', label: 'Certification' },
                        { value: 'customs', label: 'Customs' }
                    ],

                },
                currentObject: 'seaWorkflow',
                label: 'associatedServices',
                name: 'onevalue',

                value: [] // Initialize as an empty array, as multiple checkboxes can be selected
            },
            // Additional fields specific to FCL


        },





        // Additional fields specific to LCL





        landWorkflow: {
            TransportationBy: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Full Container Load FCL', displayValue: 'Full Container Load FCL' },
                        { value: 'Full Truck Load FTL', displayValue: 'Full Truck Load FTL' },
                        { value: 'Less Truck Load LTL', displayValue: 'Less Truck Load LTL' },

                        // Add more options as needed
                    ],
                    placeholder: 'Select Type'
                },
                value: 'Full Container Load FCL',
                currentObject: 'landWorkflow',
                label: 'Transportation By *',
                name: 'TransportationBy',


                landFCL: {
                    ContainerType: {
                        elementType: 'select',
                        elementConfig: {
                            options: [
                                { value: "20' Standard", displayValue: "20' Standard" }, { value: "40' Standard", displayValue: "40' Standard" }, { value: "40' High Cube", displayValue: "40' High Cube" }, { value: "45' High Cube", displayValue: "45' High Cube" }, { value: "20' Refrigerated", displayValue: "20' Refrigerated" }, { value: "40' Refrigerated", displayValue: "40' Refrigerated" }, { value: "20' Open Top", displayValue: "20' Open Top" }, { value: "40' Open Top", displayValue: "40' Open Top" }, { value: "20' Flatrack", displayValue: "20' Flatrack" }, { value: "40' Flatrack", displayValue: "40' Flatrack" }, { value: "20' Flatrack Collapsible", displayValue: "20' Flatrack Collapsible" }, { value: "40' Flatrack Collapsible", displayValue: "40' Flatrack Collapsible" }, { value: "20' High Cube", displayValue: "20' High Cube" }, { value: "20' Pallet Wide", displayValue: "20' Pallet Wide" }, { value: "20' High Cube Pallet Wide", displayValue: "20' High Cube Pallet Wide" }, { value: "40' Pallet Wide", displayValue: "40' Pallet Wide" }, { value: "40' High Cube Pallet Wide", displayValue: "40' High Cube Pallet Wide" }, { value: "45' High Cube Pallet Wide", displayValue: "45' High Cube Pallet Wide" }, { value: "10' Standard", displayValue: "10' Standard" }, { value: "20' Bulk", displayValue: "20' Bulk" }, { value: "20' Tank", displayValue: "20' Tank" }, { value: "20' Platform", displayValue: "20' Platform" }, { value: "40' Platform", displayValue: "40' Platform" }, { value: "48' High Cube", displayValue: "48' High Cube" }, { value: "53' High Cube", displayValue: "53' High Cube" }

                                // Add more options as needed
                            ],
                            placeholder: 'Select Container Type'
                        },
                        value: '',
                        label: 'Container Type *',
                        name: 'container-type'

                    },
                    QuantityOfContainers: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Quantity of Containers'
                        },
                        value: '',
                        label: 'Quantity Of Containers *',
                        name: 'quantity-of-containers'

                    },
                    Weight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Weight'
                        },
                        value: '',
                        label: 'Weight *',
                        name: 'weight'

                    },
                },

                landFTL: {
                    // TruckType: {
                    //     elementType: 'select',
                    //     elementConfig: {
                    //         options: [
                    //             { value: 'type1', displayValue: 'Type 1' },
                    //             { value: 'type2', displayValue: 'Type 2' },
                    //             // Add more options as needed
                    //         ],
                    //         placeholder: 'Select Truck Type'
                    //     },
                    //     value: '',
                    //     label: 'Truck Type *',
                    //     name: 'TruckType'

                    // },
                    // QuantityOfTrucks: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Quantity of Trucks'
                    //     },
                    //     value: '',
                    //     label: 'Quantity Of Trucks *',
                    //     name: 'QuantityOfTrucks'

                    // },
                    Volume: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Volume'
                        },
                        value: '',
                        label: 'Volume *',
                        name: 'Volume'

                    },
                },

                LCL: {
                    Dimensions: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Dimensions'
                        },
                        value: '',
                        label: 'Dimensions *',
                        name: 'Dimensions'

                    },
                    Quantity: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Quantity'
                        },
                        value: '',
                        label: 'Quantity *',
                        name: 'Quantity'

                    },
                    GrossWeight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Gross Weight'
                        },
                        value: '',
                        label: 'GrossWeight *',
                        name: 'GrossWeight'

                    },
                    // Weight: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Weight'
                    //     },
                    //     value: ''
                    // },
                    // Volume: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Volume'
                    //     },
                    //     value: ''
                    // }
                },

            },


            from: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select From'
                },
                currentObject: 'landWorkflow',

                value: '',
                label: 'From *',
                name: 'from'

            },
            to: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select To'
                },
                currentObject: 'landWorkflow',

                value: '',
                label: 'To *',
                name: 'to'

            },
            readyToLoad: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Ready To Load'
                },
                currentObject: 'landWorkflow',

                value: '',
                label: 'ready To Load *',
                name: 'ready-to-load'

            },
            additionalInformation: {
                elementType: 'textarea',
                elementConfig: {
                    placeholder: 'Additional Information'
                },
                currentObject: 'landWorkflow',

                value: '',
                label: 'additional Information *',
                name: 'message'

            },
            associatedServices: {
                elementType: 'checkboxM',
                elementConfig: {
                    type: 'checkbox',
                    options: [
                        { value: 'insurance', label: 'Insurance' },
                        { value: 'certification', label: 'Certification' },
                        { value: 'customs', label: 'Customs' }
                    ]
                },
                currentObject: 'landWorkflow',
                label: 'associated Services *',
                name: 'onevalue',
                value: [] // Initialize as an empty array, as multiple checkboxes can be selected
            }
        },


        airWorkflow: {
            TransportationBy: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Standard cargo', displayValue: 'Standard cargo' },
                        { value: 'ULD container', displayValue: 'ULD container' },

                        // Add more options as needed
                    ],
                    placeholder: 'Select Type'
                },
                value: 'Standard cargo',
                label: 'Transportation By *',
                name: 'TransportationBy',
                currentObject: 'airWorkflow',

                landFCL: {
                    // ContainerType: {
                    //     elementType: 'select',
                    //     elementConfig: {
                    //         options: [
                    //             { value: "20' Standard", displayValue: "20' Standard" }, { value: "40' Standard", displayValue: "40' Standard" }, { value: "40' High Cube", displayValue: "40' High Cube" }, { value: "45' High Cube", displayValue: "45' High Cube" }, { value: "20' Refrigerated", displayValue: "20' Refrigerated" }, { value: "40' Refrigerated", displayValue: "40' Refrigerated" }, { value: "20' Open Top", displayValue: "20' Open Top" }, { value: "40' Open Top", displayValue: "40' Open Top" }, { value: "20' Flatrack", displayValue: "20' Flatrack" }, { value: "40' Flatrack", displayValue: "40' Flatrack" }, { value: "20' Flatrack Collapsible", displayValue: "20' Flatrack Collapsible" }, { value: "40' Flatrack Collapsible", displayValue: "40' Flatrack Collapsible" }, { value: "20' High Cube", displayValue: "20' High Cube" }, { value: "20' Pallet Wide", displayValue: "20' Pallet Wide" }, { value: "20' High Cube Pallet Wide", displayValue: "20' High Cube Pallet Wide" }, { value: "40' Pallet Wide", displayValue: "40' Pallet Wide" }, { value: "40' High Cube Pallet Wide", displayValue: "40' High Cube Pallet Wide" }, { value: "45' High Cube Pallet Wide", displayValue: "45' High Cube Pallet Wide" }, { value: "10' Standard", displayValue: "10' Standard" }, { value: "20' Bulk", displayValue: "20' Bulk" }, { value: "20' Tank", displayValue: "20' Tank" }, { value: "20' Platform", displayValue: "20' Platform" }, { value: "40' Platform", displayValue: "40' Platform" }, { value: "48' High Cube", displayValue: "48' High Cube" }, { value: "53' High Cube", displayValue: "53' High Cube" }

                    //             // Add more options as needed
                    //         ],
                    //         placeholder: 'Select Container Type'
                    //     },
                    //     value: '',
                    //     label: 'Container Type *',
                    //     name: 'container-type'

                    // },
                    // QuantityOfContainers: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'number',
                    //         placeholder: 'Quantity of Containers'
                    //     },
                    //     value: '',
                    //     label: 'Quantity Of Containers *',
                    //     name: 'quantity-of-containers'

                    // },
                    Weight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Weight'
                        },
                        value: '',
                        label: 'Weight *',
                        name: 'weight'

                    },
                    Volume: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Volume'
                        },
                        value: '',
                        label: 'Volume *',
                        name: 'volume'

                    },
                },

                landFTL: {
                    // TruckType: {
                    //     elementType: 'select',
                    //     elementConfig: {
                    //         options: [
                    //             { value: 'type1', displayValue: 'Type 1' },
                    //             { value: 'type2', displayValue: 'Type 2' },
                    //             // Add more options as needed
                    //         ],
                    //         placeholder: 'Select Truck Type'
                    //     },
                    //     value: '',
                    //     label: 'Truck Type *',
                    //     name: 'TruckType'

                    // },
                    QuantityOfTrucks: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Quantity of Trucks'
                        },
                        value: '',
                        label: 'Quantity Of Trucks *',
                        name: 'QuantityOfTrucks'

                    },
                    Weight: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'number',
                            placeholder: 'Weight'
                        },
                        value: '',
                        label: 'Weight *',
                        name: 'weight'

                    },
                },


            },


            from: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select From'
                },
                currentObject: 'airWorkflow',

                value: '',
                label: 'from *',
                name: 'from'

            },
            to: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select To'
                },
                currentObject: 'airWorkflow',

                value: '',
                label: 'To *',
                name: 'to'

            },
            readyToLoad: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Ready To Load'
                },
                currentObject: 'airWorkflow',

                value: '',
                label: 'ready To Load *',
                name: 'ready-to-load'

            },
            additionalInformation: {
                elementType: 'textarea',
                elementConfig: {
                    placeholder: 'Additional Information'
                },
                currentObject: 'airWorkflow',

                value: '',
                label: 'additional Information *',
                name: 'message'

            },
            associatedServices: {
                elementType: 'checkboxM',
                elementConfig: {
                    type: 'checkbox',
                    options: [
                        { value: 'insurance', label: 'Insurance' },
                        { value: 'certification', label: 'Certification' },
                        { value: 'customs', label: 'Customs' }
                    ]
                },
                currentObject: 'airWorkflow',
                label: 'associated Services *',
                name: 'onevalue',
                value: [] // Initialize as an empty array, as multiple checkboxes can be selected
            }
        },
        autoWorkflow: {
            Weight: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Weight'
                },
                currentObject: 'autoWorkflow',

                value: '',
                label: 'Weight *',
                name: 'weight'

            },
            Volume: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Volume'
                },
                currentObject: 'autoWorkflow',
                value: '',
                label: 'Volume *',
                name: 'volume'

            },


            from: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select From'
                },
                currentObject: 'autoWorkflow',
                value: '',
                label: 'from *',
                name: 'from'

            },
            to: {
                elementType: 'select',
                elementConfig: {
                    options: [
                        { value: 'Doha', displayValue: 'Doha' }, { value: 'Al Rayyan', displayValue: 'Al Rayyan' }, { value: 'Umm Salal', displayValue: 'Umm Salal' }, { value: 'Al Daayen', displayValue: 'Al Daayen' }, { value: 'Al Khor', displayValue: 'Al Khor' }, { value: 'Mesaieed', displayValue: 'Mesaieed' }, { value: 'Dukhan', displayValue: 'Dukhan' }, { value: 'Ras Laffan', displayValue: 'Ras Laffan' }, { value: 'Al Wakrah', displayValue: 'Al Wakrah' }, { value: 'Al Ruwais', displayValue: 'Al Ruwais' }, { value: 'Al Gharrafa', displayValue: 'Al Gharrafa' }, { value: 'Al Hilal', displayValue: 'Al Hilal' }, { value: 'Industrial Area', displayValue: 'Industrial Area' }, { value: 'Izghawa', displayValue: 'Izghawa' }

                        // Add more options as needed
                    ],
                    placeholder: 'Select To'
                },
                currentObject: 'autoWorkflow',
                value: '',
                label: 'To *',
                name: 'to'

            },
            readyToLoad: {
                elementType: 'input',
                elementConfig: {
                    type: 'date',
                    placeholder: 'Ready To Load'
                },
                currentObject: 'autoWorkflow',
                value: '',
                label: 'ready To Load *',
                name: 'ready-to-load'

            },
            additionalInformation: {
                elementType: 'textarea',
                elementConfig: {
                    placeholder: 'Additional Information'
                },
                currentObject: 'autoWorkflow',
                value: '',
                label: 'additional Information *',
                name: 'message'

            },
            associatedServices: {
                elementType: 'checkboxM',
                elementConfig: {
                    type: 'checkbox',
                    options: [
                        { value: 'insurance', label: 'Insurance' },
                        { value: 'certification', label: 'Certification' },
                        { value: 'customs', label: 'Customs' }
                    ]
                },
                currentObject: 'autoWorkflow',
                label: 'associated Services *',
                name: 'onevalue',
                value: [] // Initialize as an empty array, as multiple checkboxes can be selected
            }
        },
        // inquiryForm: {


        //     from: {
        //         elementType: 'select',
        //         elementConfig: {
        //             options: [
        //                 { value: 'city1', displayValue: 'City 1' },
        //                 { value: 'city2', displayValue: 'City 2' },
        //                 // Add more options as needed
        //             ],
        //             placeholder: 'Select From'
        //         },
        //         value: ''
        //     },
        //     to: {
        //         elementType: 'select',
        //         elementConfig: {
        //             options: [
        //                 { value: 'city1', displayValue: 'City 1' },
        //                 { value: 'city2', displayValue: 'City 2' },
        //                 // Add more options as needed
        //             ],
        //             placeholder: 'Select To'
        //         },
        //         value: ''
        //     },
        //     readyToLoad: {
        //         elementType: 'input',
        //         elementConfig: {
        //             type: 'date',
        //             placeholder: 'Ready To Load'
        //         },
        //         value: ''
        //     },
        //     additionalInformation: {
        //         elementType: 'textarea',
        //         elementConfig: {
        //             placeholder: 'Additional Information'
        //         },
        //         value: ''
        //     },
        //     associatedServices: {
        //         elementType: 'checkboxM',
        //         elementConfig: {
        //             type: 'checkbox',
        //             options: [
        //                 { value: 'insurance', label: 'Insurance' },
        //                 { value: 'certification', label: 'Certification' },
        //                 { value: 'customs', label: 'Customs' }
        //             ]
        //         },
        //         value: [] // Initialize as an empty array, as multiple checkboxes can be selected
        //     }
        // },

        contactDetails: { // New object
            firstName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'First Name',
                },
                value: '',
                label: 'first Name *',
                name: 'first-name'


            },
            lastName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Last Name',
                    name: 'last-name'

                },
                value: '',
                label: 'last Name *',
                name: 'last-name'


            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Email',

                },
                value: '',
                label: 'email *',
                name: 'email'


            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'tel',
                    placeholder: 'Phone',

                },
                value: '',
                name: 'phone',

                label: 'phone *'
            },
            country: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: 'Country',

                },
                value: '',
                label: 'country *',
                name: 'country'


            },


        },

        loading: false,
    }

    // Define a function to extract values from an object
    // extractValues = (obj) => {
    //     const stack = [{ obj, prefix: '' }];
    //     const result = [];

    //     while (stack.length > 0) {
    //         const { obj, prefix } = stack.pop();

    //         for (const key in obj) {
    //             const currentValue = obj[key];

    //             if (key === 'value' && currentValue) {
    //                 // If the current key is 'value' and the value is truthy, add it to the result
    //                 result.push({ name: prefix, value: currentValue });
    //             } else if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
    //                 // If the current value is an object, push it onto the stack
    //                 stack.push({ obj: currentValue, prefix: prefix + key + '.' });
    //             }
    //         }
    //     }

    //     return result;
    // };



    submitHandler = (event) => {
        event.preventDefault();

        // // Extract the values from the seaWorkflow and contactDetails objects
        // let formData;
        // if (this.state.selectedArray === 'seaElementsArray') {
        //     formData = this.extractValues(this.state.seaWorkflow);

        // }
        // if (this.state.selectedArray === 'landElementsArray') {
        //     formData = this.extractValues(this.state.landWorkflow);

        // }
        // if (this.state.selectedArray === 'airElementsArray') {
        //     formData = this.extractValues(this.state.airWorkflow);

        // }
        // if (this.state.selectedArray === 'autoElementsArray') {
        //     formData = this.extractValues(this.state.autoWorkflow);

        // }
        // formData = this.extractValues(this.state.seaWorkflow);
        // const contactDetailsValues = this.extractValues(this.state.contactDetails);

        // // Define the template parameters for the email
        // const templateParams = {
        //     // Add more template parameters as needed for your email template
        //     formData,
        //     contactDetailsValues,
        // };

        // console.log(templateParams);



        // Send email using EmailJS
        emailjs.sendForm('service_nqmmbzz', 'template_q5q399l', this.formRef.current, '90mmZWMahlk0pxg45')
            .then((response) => {
                console.log('Email sent successfully:', response);
                this.formRef.current.reset(); // Reset the form fields
                alert('Email sent successfully!'); // Show a success alert message

                // Reset the form
                this.formRef.current.reset(); // Reset the form
            })
            .catch((error) => {
                console.error('Email sending error:', error);
            });

        // window.location.href = '/';

    }


    handleSeaButtonClick = () => {
        this.setState({ selectedArray: 'seaElementsArray' });
    };

    handleLandButtonClick = () => {
        this.setState({ selectedArray: 'landElementsArray' });
    };

    handleAirButtonClick = () => {
        this.setState({ selectedArray: 'airElementsArray' });
    };

    handleAutoButtonClick = () => {
        this.setState({ selectedArray: 'autoElementsArray' });
    };

    handleInputChange = (val) => {
        this.setState({})
    }

    inputChangedHandler = (event, inputIdentifier, rootObject, nestedValues) => {
        console.log("BEFORE", event.target.value, inputIdentifier, rootObject, nestedValues)


        // Create a copy of the state to avoid mutating it directly
        const updatedSeaWorkflow = { ...this.state.seaWorkflow };

        // Check if the input is within TransportationBy
        if (rootObject === 'seaWorkflow' && updatedSeaWorkflow.hasOwnProperty(inputIdentifier)) {
            updatedSeaWorkflow[inputIdentifier].value = event.target.value;
            console.log('AFTER', updatedSeaWorkflow[inputIdentifier].value)
        } else {
            // Check if the input is within FCL, LCL, or Bulk
            for (const key in updatedSeaWorkflow.TransportationBy) {
                if (updatedSeaWorkflow.TransportationBy[key].hasOwnProperty(inputIdentifier)) {
                    updatedSeaWorkflow.TransportationBy[key][inputIdentifier].value = event.target.value;
                    console.log("AFTER", updatedSeaWorkflow.TransportationBy[key][inputIdentifier].value)
                    break;
                }
            }
        }

        // Update the state with the modified seaWorkflow object
        this.setState({ seaWorkflow: updatedSeaWorkflow });



        // Create a copy of the state to avoid mutating it directly
        const updatedLandWorkflow = { ...this.state.landWorkflow };

        // Check if the input is within TransportationBy
        if (rootObject === 'landWorkflow' && updatedLandWorkflow.hasOwnProperty(inputIdentifier)) {
            updatedLandWorkflow[inputIdentifier].value = event.target.value;
            console.log('AFTER', updatedLandWorkflow[inputIdentifier].value);
        } else {
            // Check if the input is within landFCL, landFTL, LCL, etc.
            for (const key in updatedLandWorkflow.TransportationBy) {
                if (updatedLandWorkflow.TransportationBy[key].hasOwnProperty(inputIdentifier)) {
                    updatedLandWorkflow.TransportationBy[key][inputIdentifier].value = event.target.value;
                    console.log("AFTER", updatedLandWorkflow.TransportationBy[key][inputIdentifier].value);
                    break;
                }
            }
        }

        // Update the state with the modified landWorkflow object
        this.setState({ landWorkflow: updatedLandWorkflow });





        // Create a copy of the state to avoid mutating it directly
        const updatedAirWorkflow = { ...this.state.airWorkflow };

        // Check if the input is within TransportationBy
        if (rootObject === 'airWorkflow' && updatedAirWorkflow.hasOwnProperty(inputIdentifier)) {
            updatedAirWorkflow[inputIdentifier].value = event.target.value;
            console.log('AFTER', updatedAirWorkflow[inputIdentifier].value);
        } else {
            // Check if the input is within landFCL, landFTL, or other nested fields
            for (const key in updatedAirWorkflow.TransportationBy) {
                if (updatedAirWorkflow.TransportationBy[key].hasOwnProperty(inputIdentifier)) {
                    updatedAirWorkflow.TransportationBy[key][inputIdentifier].value = event.target.value;
                    console.log("AFTER", updatedAirWorkflow.TransportationBy[key][inputIdentifier].value);
                    break;
                }
            }
        }

        // Update the state with the modified airWorkflow object
        this.setState({ airWorkflow: updatedAirWorkflow });


        // Create a copy of the state to avoid mutating it directly
        const updatedAutoWorkflow = { ...this.state.autoWorkflow };

        // Check if the input is within autoWorkflow
        if (rootObject === 'autoWorkflow' && updatedAutoWorkflow.hasOwnProperty(inputIdentifier)) {
            updatedAutoWorkflow[inputIdentifier].value = event.target.value;
            console.log('AFTER', updatedAutoWorkflow[inputIdentifier].value);
        }

        // Update the state with the modified autoWorkflow object
        this.setState({ autoWorkflow: updatedAutoWorkflow });

        if (rootObject === 'contactDetails') {
            const updatedAutoWorkflow = {
                ...this.state.contactDetails
            }

            const updatedAutoWorkflowElement = {
                ...updatedAutoWorkflow[inputIdentifier]
            }

            updatedAutoWorkflowElement.value = event.target.value
            updatedAutoWorkflow[inputIdentifier] = updatedAutoWorkflowElement
            this.setState({ contactDetails: updatedAutoWorkflow })
            // console.log(this.state.seaWorkflow.TransportationBy.value, 'value')
            console.log("ContactDetails", event.target.value, updatedAutoWorkflowElement.value)

        }




    }

    handleProductChange = (event) => {
        let updatevalue = this.state.productValue;
        updatevalue = event.target.value
        this.setState({ productValue: updatevalue })

        // console.log(this.state.productValue)
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission behavior
        }
    }

    handleMetricChange = (selectedOption) => {
        this.setState({ selectedOption });

        // Function to handle radio button selection

    };

    handleOptionChange = (cargo) => {
        this.setState({ selectedCargo: cargo });
        console.log(this.state.selectedCargo)

    };
    render() {

        const radioContainerStyle = {
            display: 'flex',
            alignItems: 'center',
        };

        const radioBoxStyle = {
            display: 'flex',
            width: '200px',
            alignItems: 'center',
            marginRight: '10px', // Adjust spacing between boxes as needed
            cursor: 'pointer', // Add cursor pointer for better UX
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Apply shadow
            borderRadius: '12px', // Apply border radius
            padding: '3px', // Add padding for spacing
            transition: 'background-color 0.2s, color 0.2s', // Add smooth transition
            backgroundColor: 'white', // Set initial background color
            color: 'black', // Set initial text color
        };

        // Add a hover effect
        radioBoxStyle[':hover'] = {
            backgroundColor: 'black', // Change background color to white on hover
            color: 'green', // Change text color to green on hover
        };

        const radioLabelStyle = {
            marginLeft: '10px', // Adjust spacing between icon and text as needed
            display: 'flex',
            alignItems: 'center',
            fontSize: '10px', // Apply font size style
            lineHeight: 'calc(2.2*var(--rem-base))', // Apply line height style
            fontWeight: '400', // Apply font weight style
            cursor: 'pointer',
            color: 'inherit', // Inherit text color from parent div

        };

        const iconStyle = {
            width: '20px', // Adjust icon width as needed
            height: '20px', // Adjust icon height as needed
            marginRight: '5px', // Adjust spacing between icon and text as needed
        };
        const options = [
            { value: 'international', label: 'International(SI)' },
            { value: 'imperial', label: 'Imperial(US)' },
        ];

        const cargoOptions = [
            'Hazardous Cargo',
            'Perishable Cargo',
            'Oversized Cargo',
            'Liquid Cargo',
        ];

        const cargoIcons = [
            'https://cdn0.iconfinder.com/data/icons/logistics-91/512/Logistics_flammable-fire-sign-danger-package-1024.png',
            'https://th.bing.com/th/id/OIP.VR6aszIBf6sUNNFuCNaa-wHaHa?pid=ImgDet&rs=1',
            'https://th.bing.com/th/id/OIP.l6w7BWzWfl2QztDVnI2McgHaIT?pid=ImgDet&rs=1',
            'https://th.bing.com/th/id/OIP.0Lfwd6fGExaP64mRkQ1zIwAAAA?pid=ImgDet&rs=1',
        ];



        // let formElementsArray = [];
        // for (let key in this.state.inquiryForm) {
        //     formElementsArray.push({
        //         id: key,
        //         config: this.state.inquiryForm[key]
        //     })
        //     console.log(key)
        // }

        //sea transportation values
        let FCLArray = [];
        for (let key in this.state.FCL) {
            FCLArray.push({
                id: key,
                config: this.state.FCL[key]
            })
        }

        let LCLArray = [];
        for (let key in this.state.LCL) {
            LCLArray.push({
                id: key,
                config: this.state.LCL[key]
            })
        }

        // console.log(LCLArray, 'LCLArray')
        let BulkArray = [];
        for (let key in this.state.Bulk) {
            BulkArray.push({
                id: key,
                config: this.state.Bulk[key]
            })
        }


        // sea workflow
        let seaElementsArray = [];
        for (let key in this.state.seaWorkflow) {
            seaElementsArray.push({
                id: key,
                config: this.state.seaWorkflow[key]
            })
        }
        // console.log(seaElementsArray[0].config.value, 'seaelements')
        // console.log(seaElementsArray[0].config.elementConfig.options.map(val => {
        //     // if(val.value === "Full Container Load FCL") return
        //     return (val.value === 'Full Container Load FCL') ? true : false
        // }))


        //land transportation values
        let landFCLArray = [];
        for (let key in this.state.landFCL) {
            landFCLArray.push({
                id: key,
                config: this.state.landFCL[key]
            })
        }

        let landFTLArray = [];
        for (let key in this.state.landFTL) {
            landFTLArray.push({
                id: key,
                config: this.state.landFTL[key]
            })
        }

        // land workflow
        let landElementsArray = [];
        for (let key in this.state.landWorkflow) {
            landElementsArray.push({
                id: key,
                config: this.state.landWorkflow[key]
            })
        }

        // Air workflow
        let airElementsArray = [];
        for (let key in this.state.airWorkflow) {
            airElementsArray.push({
                id: key,
                config: this.state.airWorkflow[key]
            })
        }
        // Auto workflow
        let autoElementsArray = [];
        for (let key in this.state.autoWorkflow) {
            autoElementsArray.push({
                id: key,
                config: this.state.autoWorkflow[key]
            })
        }

        //contact details
        let contactDetailsElementsArray = [];
        for (let key in this.state.contactDetails) {
            contactDetailsElementsArray.push({
                id: key,
                config: this.state.contactDetails[key]
            });
        }


        let form = (
            <form ref={this.formRef} onSubmit={this.submitHandler}>



                <label style={{ textAlign: 'left', fontSize: '12px', margin: '0px' }} className={classes.Lable}>Product *</label>
                <input onKeyPress={this.handleKeyPress} style={{ marginBottom: '25px', marginTop: '2px' }} className={classes.InputEle} type='text' name='product_name' placeholder='Enter commodity type' onChange={this.handleProductChange}></input>
                {/* Radio buttons */}
                <div>
                    <label style={{ marginTop: '30px', marginBottom: '15px' }}>Select Cargo Type:</label>
                    <div style={radioContainerStyle}>
                        {cargoOptions.map((cargo, index) => (
                            <div
                                key={cargo}
                                style={{
                                    ...radioBoxStyle,
                                    backgroundColor:
                                        this.state.selectedCargo === cargo ? 'lightblue' : 'white', // Change background color conditionally
                                }}
                                onClick={() => this.handleOptionChange(cargo)}
                                className={this.state.selectedCargo === cargo ? 'selected' : ''}
                            >
                                <span>
                                    {/* Icon/Image */}
                                    <img
                                        src={cargoIcons[index]} // Assuming you have cargoIcons array
                                        alt="Cargo Icon"
                                        style={iconStyle}
                                    />
                                </span>
                                <label style={radioLabelStyle}>{cargo}</label>

                            </div>
                        ))}
                    </div>
                </div>

                
                <input type="hidden" name="selectedCargo" value={this.state.selectedCargo} />

                {/* Add a hidden input field for the selected option */}
                <input type="hidden" name="selectedOption" value={this.state.selectedOption.value} />

                <input type="hidden" name="selectedArray" value={this.state.selectedArray} />




                <h3 style={{ textAlign: 'center', marginTop: '60px', marginBottom: '20px' }}>Delivery</h3>
                <div className={classes.buttoncontainer} style={{ marginBottom: '50px' }}>
                    <button
                        type="button"
                        className={classes.ButtonList}
                        onClick={this.handleSeaButtonClick}

                    >
                        <svg width="13" height="11" fill="#08F" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#ship_svg__clip0_2179_41532)">
                                <path d="M10.078 1.97v2.873l-1.26-.422V2.626h-5.04V4.42l-1.26.422V1.97a.67.67 0 01.185-.464.617.617 0 01.446-.193h1.26v-.82c0-.13.05-.256.138-.348A.463.463 0 014.88.001h2.835c.125 0 .245.052.334.144a.503.503 0 01.138.348v.82h1.26c.167 0 .328.07.446.193a.67.67 0 01.184.464z"></path>
                                <path d="M12.6 9.68v.328c0 .13-.05.256-.139.348a.463.463 0 01-.334.145c-1.2 0-2.116-.423-2.82-1.219-.143.36-.385.669-.696.885a1.84 1.84 0 01-1.051.334H5.04a1.84 1.84 0 01-1.05-.334 1.956 1.956 0 01-.697-.885c-.704.796-1.62 1.219-2.82 1.219a.463.463 0 01-.335-.145.503.503 0 01-.138-.348V9.68c0-.13.05-.255.138-.348a.463.463 0 01.334-.144c1.213 0 2.005-.65 2.35-1.545L1.446 6.207a.683.683 0 01-.016-.91.627.627 0 01.268-.179l4.41-1.476a.605.605 0 01.386 0l4.41 1.476a.627.627 0 01.268.179.683.683 0 01-.016.91L9.777 7.643c.351.91 1.153 1.545 2.35 1.545.125 0 .246.052.334.144a.503.503 0 01.139.348z"></path>
                            </g>
                            <defs>
                                <clipPath id="ship_svg__clip0_2179_41532">
                                    <path fill="#fff" transform="translate(0 .001)" d="M0 0h12.6v10.5H0z"></path>
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="MyNzRc">SEA</span>
                    </button>

                    <button
                        type="button"
                        className={classes.ButtonList}
                        onClick={this.handleLandButtonClick}

                    >
                        <svg width="12" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.658 8.107L9.164 1.123a.953.953 0 00-.896-.632H6.635V2.08a.317.317 0 01-.318.317h-.635a.317.317 0 01-.317-.317V.49H3.734a.952.952 0 00-.897.632L.343 8.107a.95.95 0 00.896 1.272h4.126V7.792c0-.175.143-.317.317-.317h.635c.176 0 .318.142.318.317v1.587h4.109a.955.955 0 00.914-1.272zm-5.023-2.22a.317.317 0 01-.318.318h-.635a.318.318 0 01-.317-.318V3.982c0-.174.143-.317.317-.317h.635c.175 0 .318.143.318.317v1.905z" fill="#02BCB1"></path>
                        </svg>
                        <span className="MyNzRc">LAND</span>
                    </button>

                    <button type="button" className={classes.ButtonList}
                        onClick={this.handleAirButtonClick}
                    >
                        <FaPlane /> {/* Render the Font Awesome plane icon */}
                        <span className="MyNzRc">AIR</span>
                    </button>

                    <button type="button" className={classes.ButtonList}
                        onClick={this.handleAutoButtonClick}
                    >
                        <FaRocket /> {/* Render the Font Awesome rocket icon */}
                        <span className="MyNzRc">AUTO</span>
                    </button>


                </div>

                {/* {formElementsArray.map(formElement => (
                    <React.Fragment key={formElement.id}>
                        {formElement.id === 'associatedServices' ? (
                            <h4>Associated Services</h4>
                        ) : null}
                        <Input
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                        />
                    </React.Fragment>
                ))} */}

                {this.state.selectedArray === 'seaElementsArray' && seaElementsArray.map(formElement => (
                    <React.Fragment key={formElement.id}>
                        {formElement.id === 'associatedServices' ? (
                            <h3 style={{ textAlign: 'center', fontSize: '12px', textTransform: 'uppercase', color: '#000c37', marginLeft: '15px', marginTop: '30px' }}>Associated Services *</h3>
                        ) : null}

                        <Input
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            changed={(event) => this.inputChangedHandler(event, formElement.id, formElement.config.currentObject)}
                            name={formElement.config.name}
                            lable={formElement.config.label}
                        />

                        {formElement.id === 'TransportationBy' && formElement.config.elementType === 'select' ? (
                            // Render nested fields based on the selected option
                            formElement.config.value === 'Full Container Load FCL' ? (
                                <React.Fragment>
                                    {/* <h4 style={{ textAlign: 'center' }}>FCL</h4> */}
                                    {Object.keys(formElement.config.FCL).map((nestedFieldId) => (
                                        <Input
                                            key={nestedFieldId}
                                            elementType={formElement.config.FCL[nestedFieldId].elementType}
                                            elementConfig={formElement.config.FCL[nestedFieldId].elementConfig}
                                            value={formElement.config.FCL[nestedFieldId].value}
                                            changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'FCL')}
                                            lable={formElement.config.FCL[nestedFieldId].label}
                                            name={formElement.config.FCL[nestedFieldId].name}


                                        />
                                    ))}
                                </React.Fragment>
                            ) : formElement.config.value === 'Less Container Load LCL' ? (
                                <React.Fragment>
                                    {/* <h4 style={{ textAlign: 'center' }}>LCL</h4> */}
                                    {Object.keys(formElement.config.LCL).map((nestedFieldId) => (
                                        <Input
                                            key={nestedFieldId}
                                            elementType={formElement.config.LCL[nestedFieldId].elementType}
                                            elementConfig={formElement.config.LCL[nestedFieldId].elementConfig}
                                            value={formElement.config.LCL[nestedFieldId].value}
                                            changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject)}
                                            lable={formElement.config.LCL[nestedFieldId].label}

                                        />
                                    ))}
                                </React.Fragment>
                            ) : formElement.config.value === 'Bulk' ? (
                                <React.Fragment>
                                    {/* <h4 style={{ textAlign: 'center' }}>Bulk</h4> */}
                                    {Object.keys(formElement.config.Bulk).map((nestedFieldId) => (
                                        <Input
                                            key={nestedFieldId}
                                            elementType={formElement.config.Bulk[nestedFieldId].elementType}
                                            elementConfig={formElement.config.Bulk[nestedFieldId].elementConfig}
                                            value={formElement.config.Bulk[nestedFieldId].value}
                                            changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject)}
                                            lable={formElement.config.Bulk[nestedFieldId].label}

                                        />
                                    ))}
                                </React.Fragment>
                            ) : null
                        ) : null}

                    </React.Fragment>
                ))}





                {
                    this.state.selectedArray === 'landElementsArray' && landElementsArray.map(formElement => (
                        <React.Fragment key={formElement.id}>
                            {formElement.id === 'associatedServices' ? (
                                <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Associated Services *</h3>
                            ) : null}
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={(event) => this.inputChangedHandler(event, formElement.id, formElement.config.currentObject)}
                                lable={formElement.config.label}
                                name={formElement.config.name}


                            />

                            {formElement.id === 'TransportationBy' && formElement.config.elementType === 'select' ? (
                                // Render nested fields based on the selected option
                                formElement.config.value === 'Full Container Load FCL' ? (
                                    <React.Fragment>
                                        {/* <h4 style={{ textAlign: 'center' }}>Land FCL</h4> */}
                                        {Object.keys(formElement.config.landFCL).map((nestedFieldId) => (
                                            <Input
                                                key={nestedFieldId}
                                                elementType={formElement.config.landFCL[nestedFieldId].elementType}
                                                elementConfig={formElement.config.landFCL[nestedFieldId].elementConfig}
                                                value={formElement.config.landFCL[nestedFieldId].value}
                                                changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'landFCL')}
                                                lable={formElement.config.landFCL[nestedFieldId].label}
                                                name={formElement.config.landFCL[nestedFieldId].name}


                                            />
                                        ))}
                                    </React.Fragment>
                                ) : formElement.config.value === 'Full Truck Load FTL' ? (
                                    <React.Fragment>
                                        {/* <h4 style={{ textAlign: 'center' }}>Land FTL</h4> */}
                                        {Object.keys(formElement.config.landFTL).map((nestedFieldId) => (
                                            <Input
                                                key={nestedFieldId}
                                                elementType={formElement.config.landFTL[nestedFieldId].elementType}
                                                elementConfig={formElement.config.landFTL[nestedFieldId].elementConfig}
                                                value={formElement.config.landFTL[nestedFieldId].value}
                                                changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'landFTL')}
                                                lable={formElement.config.landFTL[nestedFieldId].label}
                                                name={formElement.config.landFTL[nestedFieldId].name}


                                            />
                                        ))}
                                    </React.Fragment>
                                ) : formElement.config.value === 'Less Truck Load LTL' ? (
                                    <React.Fragment>
                                        {/* <h4 style={{ textAlign: 'center' }}>LCL</h4> */}
                                        {Object.keys(formElement.config.LCL).map((nestedFieldId) => (
                                            <Input
                                                key={nestedFieldId}
                                                elementType={formElement.config.LCL[nestedFieldId].elementType}
                                                elementConfig={formElement.config.LCL[nestedFieldId].elementConfig}
                                                value={formElement.config.LCL[nestedFieldId].value}
                                                changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'LCL')}
                                                lable={formElement.config.LCL[nestedFieldId].label}
                                                name={formElement.config.LCL[nestedFieldId].name}


                                            />
                                        ))}
                                    </React.Fragment>
                                ) : null
                            ) : null}
                        </React.Fragment>
                    ))
                }


                {
                    this.state.selectedArray === 'airElementsArray' && airElementsArray.map(formElement => (
                        <React.Fragment key={formElement.id}>
                            {formElement.id === 'associatedServices' ? (
                                <h3 style={{ textAlign: 'center', marginTop: '50px' }}>Associated Services *</h3>
                            ) : null}
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={(event) => this.inputChangedHandler(event, formElement.id, formElement.config.currentObject)}
                                lable={formElement.config.label}
                                name={formElement.config.name}


                            />

                            {formElement.id === 'TransportationBy' && formElement.config.elementType === 'select' ? (
                                // Render nested fields based on the selected option
                                formElement.config.value === 'Standard cargo' ? (
                                    <React.Fragment>
                                        {/* <h4 style={{ textAlign: 'center' }}>Land FCL</h4> */}
                                        {Object.keys(formElement.config.landFCL).map((nestedFieldId) => (
                                            <Input
                                                key={nestedFieldId}
                                                elementType={formElement.config.landFCL[nestedFieldId].elementType}
                                                elementConfig={formElement.config.landFCL[nestedFieldId].elementConfig}
                                                value={formElement.config.landFCL[nestedFieldId].value}
                                                changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'landFCL')}
                                                lable={formElement.config.landFCL[nestedFieldId].label}
                                                name={formElement.config.landFCL[nestedFieldId].name}


                                            />
                                        ))}
                                    </React.Fragment>
                                ) : formElement.config.value === 'ULD container' ? (
                                    <React.Fragment>
                                        {/* <h4 style={{ textAlign: 'center' }}>Land FTL</h4> */}
                                        {Object.keys(formElement.config.landFTL).map((nestedFieldId) => (
                                            <Input
                                                key={nestedFieldId}
                                                elementType={formElement.config.landFTL[nestedFieldId].elementType}
                                                elementConfig={formElement.config.landFTL[nestedFieldId].elementConfig}
                                                value={formElement.config.landFTL[nestedFieldId].value}
                                                changed={(event) => this.inputChangedHandler(event, nestedFieldId, formElement.config.currentObject, 'landFTL')}
                                                lable={formElement.config.landFTL[nestedFieldId].label}
                                                name={formElement.config.landFTL[nestedFieldId].name}


                                            />
                                        ))}
                                    </React.Fragment>
                                ) : null
                            ) : null}
                        </React.Fragment>
                    ))
                }


                {
                    this.state.selectedArray === 'autoElementsArray' && autoElementsArray.map(formElement => (
                        <React.Fragment key={formElement.id}>
                            {formElement.id === 'associatedServices' ? (
                                <h3 style={{ textAlign: 'center', marginTop: '40px' }}>Associated Services *</h3>
                            ) : null}
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={(event) => this.inputChangedHandler(event, formElement.id, formElement.config.currentObject)}
                                lable={formElement.config.label}
                                name={formElement.config.name}


                            />
                        </React.Fragment>
                    ))
                }



                <h3 style={{ textAlign: 'center', marginTop: '70px', marginBottom: '30px' }}>Contact Details</h3>
                {
                    contactDetailsElementsArray.map(formElement => (
                        <Input
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            changed={(event) => this.inputChangedHandler(event, formElement.id, 'contactDetails')}
                            lable={formElement.config.label}
                            name={formElement.config.name}


                        />
                    ))
                }
                <button type='submit' className={classes.ButtonForComponent} >Submit</button>
            </form >
        );
        if (this.state.loading) {
            form = <h1>Form is Loading</h1>
        }

        return (
            <div>
                <Link to="/"><h2 style={{ marginTop: '30px', marginLeft: '90px' }}><img className={classes.logoImg} src={clickfreight} alt='clickfreight' /></h2></Link> {/* Add this line */}





                <div className={classes.InquiryForm}>

                    <div style={{ display: 'flex', marginTop: '30px', marginBottom: '50px' }}>
                        <h1 style={{ textAlign: 'center', marginLeft: '00px' }}>Request a quote</h1>

                        <div style={{ width: '200px', marginLeft: '200px' }}>
                            <Select
                                options={options}
                                value={this.state.selectedOption}
                                onChange={this.handleMetricChange}
                                aria-label="Custom Dropdown"
                            />
                        </div>
                    </div>
                    <h2 style={{ marginBottom: '25px', textAlign: 'center' }}>Cargo details</h2>
                    {form}


                </div>

            </div>

        )
    }
}







// name: {
//     elementType: 'input',
//     elementConfig: {
//         type: 'text',
//         placeholder: 'Your Name'
//     },
//     value: ''
// },
// street: {
//     elementType: 'input',
//     elementConfig: {
//         type: 'text',
//         placeholder: 'Street'
//     },
//     value: ''
// },
// zipcode: {
//     elementType: 'input',
//     elementConfig: {
//         type: 'text',
//         placeholder: 'ZIP Code'
//     },
//     value: ''
// },
// country: {
//     elementType: 'input',
//     elementConfig: {
//         type: 'text',
//         placeholder: 'Country'
//     },
//     value: ''
// },
// email: {
//     elementType: 'input',
//     elementConfig: {
//         type: 'email',
//         placeholder: 'Your E-Mail'
//     },
//     value: ''
// },
// deliveryMethod: {
//     elementType: 'select',
//     elementConfig: {
//         options: [
//             { value: 'fastest', displayValue: 'Fastest' },
//             { value: 'cheapest', displayValue: 'Cheapest' }
//         ]
//     }
// },

// agreeToTerms: { // New checkbox element
//     elementType: 'checkbox',
//     elementConfig: {
//         type: 'checkbox',
//         label: 'I agree to the terms and conditions'
//     },
//     value: false // Initialize the checkbox as unchecked
// },