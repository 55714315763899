import React from 'react'
import LandingPage from './components/LandingPage';
import Register from './components/Register';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InquiryForm from './components/Forms/InquiryForm/InquiryForm';






const App = () => {
  return (
    <div>
       <Router>
         <Routes>
           <Route path="/" element={<LandingPage />} />
           <Route path="/register" element={<Register />} />
           <Route path="/inquiry-form" element={<InquiryForm />} />
         </Routes>
       </Router>
      
    </div>
  )
}

export default App



