import React from 'react'
import classes from './Input.module.css'

const Input = (props) => {

    let inputEle = null;

    switch (props.elementType) {
        case ('input'):
            inputEle = <input
                className={classes.InputEle}
                {...props.elementConfig} value={props.value} name = {props.name}
                onChange={props.changed} />;
            break;
        case ('textarea'):
            inputEle = <textarea
                className={classes.InputEle}
                {...props.elementConfig} value={props.value} name = {props.name}
                onChange={props.changed} />;
            break;
        case ('select'):
            inputEle =
                <select
                    className={classes.InputEle}
                    value={props.value} onChange={props.changed} name = {props.name}
                >
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} value={option.value}>{option.displayValue}</option>
                    ))}
                </select>;
            break;
        case 'checkbox':
            inputEle = (
                <input
                    type="checkbox"
                    className={classes.InputEle}
                    {...props.elementConfig}
                    checked={props.value}
                    onChange={props.changed}
                    name = {props.name}
                />
            );
            break;
        case 'checkboxM':
            inputEle = (
                <div>
                    {props.elementConfig.options.map(option => (
                        <label style={{ display: 'inline-block', padding: '6px 10px', textAlign: 'center', marginLeft:'90px', fontWeight: '500' }} key={option.value}>
                            <input
                                key={option.value}
                                type="checkbox"
                                className={classes.InputEle}
                                value={option.value}
                                name = {props.name}
                                checked={props.value.includes(option.value)} // Check if the option's value is in the array of selected values
                                onChange={(event) => props.changed(event, props.elementConfig, option.value)} // Pass the selected value to the change handler
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            );
            break;

        default:
            inputEle = <input
                className={classes.InputEle}
                {...props.elementConfig} value={props.value} name = {props.name}
                onChange={props.changed} />;
    }

    return (
        <div className={classes.Input}>
            {props.lable == 'associatedServices'? <label style= {{textAlign:'center'}} className={classes.Lable}> </label> : <label className={classes.Lable}>{props.lable}</label>}
            {/* <label className={classes.Lable}>{props.lable}</label> */}
            {inputEle}
        </div>
    )
}

export default Input
