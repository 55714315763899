import React from 'react'
import "./Register.css"
import { Link } from 'react-router-dom';

import { useState, useEffect } from 'react';



const Register = () => {

    const [name, setName] = useState('');
    const [website, setWebsite] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [service, setService] = useState('');
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [fax, setFax] = useState('');
    const [logo, setLogo] = useState(null); // Store the file object

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleLogoChange = (e) => {
        const selectedLogo = e.target.files[0]; // Get the selected file
        setLogo(selectedLogo); // Update the logo state with the selected file
        console.log(logo)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData(); // Create a FormData object

            // Append form fields
            formData.append('name', name);
            formData.append('website', website);
            formData.append('email', email);
            formData.append('company', company);
            formData.append('address', address);
            formData.append('country', country);
            formData.append('service', service);
            formData.append('phone', phone);
            formData.append('mobile', mobile);
            formData.append('fax', fax);

            // ... (append other form fields)

            // Append the image file
            formData.append('logo', logo);

            console.log('Form Data:', logo);

            // Send a POST request to your backend API
            // const apiUrl = 'https://click-freight-phase-1.onrender.com/api/submit-form';
            // const apiUrl = 'http://localhost:8000/api/submit-form';
            const apiUrl = `${process.env.REACT_APP_API_URL}api/submit-form`;


            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData, // Use the FormData object
            });

            if (response.ok) {
                // Reset form fields
                setName('');
                // ... (reset other fields)

                setIsSubmitted(true);
                setShowPopup(true);

                // Hide the popup after 2 seconds
                setTimeout(() => {
                    setShowPopup(false);

                    // Redirect to the home page after 2 seconds
                    setTimeout(() => {
                        setRedirect(true);
                    }, 2000);
                }, 2000);
            } else {
                console.error('Error submitting the form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Redirect to the home page
    useEffect(() => {
        if (redirect) {
            window.location.href = '/';
        }
    }, [redirect]);
    return (
        <div className='container-register'>
            <h1>Register Here</h1>
            <p>Provide below information in the form,
                <br />so that we can evalute your details and get back to you.</p>

            <div className='contact-box'>
                <div className='contact-left'>
                    <h3> Registration Form</h3>

                    {isSubmitted ? (
                        <div className="text-green-600 font-bold mb-4">
                            Form submitted successfully!
                        </div>
                    ) : null}

                    <form onSubmit={handleSubmit}>

                        <div className='input-row'>
                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="name"
                                >
                                    Name
                                </label>
                                <input
                                    className=""
                                    id="name"
                                    type="text"
                                    required=""
                                    value={name} // Bind value to the 'name' state variable
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="service"
                                >
                                    Service
                                </label>
                                <input
                                    className=""
                                    id="service"
                                    type="text"
                                    required=""
                                    value={service} // Set the 'website' state as the value
                                    onChange={(e) => setService(e.target.value)}
                                />
                            </div>

                        </div>


                        <div className='input-row'>
                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className=""
                                    id="email"
                                    type="email"
                                    required=""
                                    value={email} // Set the 'website' state as the value
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="mobile"
                                >
                                    Mobile
                                </label>
                                <input
                                    className=""
                                    id="mobile"
                                    type="tel"
                                    required=""
                                    value={mobile} // Set the 'website' state as the value
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div>

                        </div>

                        <div className='input-row'>
                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="address"
                                >
                                    Address
                                </label>
                                <input
                                    className=""
                                    id="address"
                                    type="text"
                                    required=""
                                    value={address} // Set the 'website' state as the value
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="country"
                                >
                                    Country
                                </label>
                                <input
                                    className=""
                                    id="country"
                                    type="text"
                                    required=""
                                    value={country} // Set the 'website' state as the value
                                    onChange={(e) => setCountry(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='input-row'>


                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="website"
                                >
                                    Website URL
                                </label>
                                <input
                                    className=""
                                    id="website"
                                    type="url"
                                    value={website} // Set the 'website' state as the value
                                    onChange={(e) => setWebsite(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="phone"
                                >
                                    Phone
                                </label>
                                <input
                                    className=""
                                    id="phone"
                                    type="tel"
                                    value={phone} // Set the 'website' state as the value
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='input-row'>

                            <div className="input-group">
                                <label
                                    className=""
                                    htmlFor="company"
                                >
                                    Company name
                                </label>
                                <input
                                    className=""
                                    id="company"
                                    type="text"
                                    required=""
                                    value={company} // Set the 'website' state as the value
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </div>

                            <div className="input-group">
                                <label

                                    htmlFor="fax"
                                >
                                    Fax
                                </label>
                                <input

                                    id="fax"
                                    type="tel"
                                    required=""
                                    value={fax} // Set the 'website' state as the value
                                    onChange={(e) => setFax(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='input-row'>
                            <div className="">
                                <label
                                    className=""
                                    htmlFor="logo"
                                >
                                    Upload company logo
                                </label>
                                <input
                                    className=""
                                    id="logo"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleLogoChange(e)}

                                />
                            </div>
                        </div>


                        <button
                            className="button-register" // Added mt-4 for margin
                            type="submit"
                        >
                            Register
                        </button>


                    </form>

                  
                    {isSubmitted && showPopup && (
                        <div className="popup">
                            {/* Your success message */}
                            <span style={{ fontWeight: 'bold', color: 'red' , marginTop: 'px'}}>Success! Your data has been submitted.</span>

                        </div>
                    )}

                </div>
                <div className='contact-right'>
                    <h3>Reach Us</h3>

                    <table>
                        <tbody>
                            <tr>
                                <td>Email</td>
                                <td>abdelghani@clickfreights.com</td>
                            </tr>

                            <tr>
                                <td>Phone</td>
                                <td>+974 66540066</td>
                            </tr>

                            {/* <tr>
                                <td>Address</td>
                                <td>#212, Ground Floor, Cafeteria H13 Building</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default Register

