import React from 'react'
import { Link } from 'react-router-dom';
import classes from './Footer.module.css'


const Footer = (props) => {


    const linkStyles = {
        textDecoration: 'none', // Remove underlines
        color: 'black',        // Set the desired text color
    };

    return (
        <div className={classes.footer}>
        <div className={classes.footerSection}>
          <h4>Social Media links</h4>
          <ul className={classes.socialmedia}>
            <Link style={linkStyles} to='/'>
              <li>Twitter</li>
            </Link>
            <Link style={linkStyles} to='/'>
              <li>Facebook</li>
            </Link>
            <Link style={linkStyles} to='/'>
              <li>Instagram</li>
            </Link>
          </ul>
        </div>
        <div className={classes.footerSection}>
          <h4>Services </h4>
          <ul className={classes.serviceList}>
            <li>Live Quotes</li>
            <li>Dedicated Quotes</li>
            <li>Flexibility</li>
          </ul>
        </div>
        <div className={classes.footerSection}>
          <form ref={props.form} onSubmit={props.mail} className={classes.form}>
            <div className={classes.formHeader}>
              <h4>Get In Touch</h4>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="user_email">Email</label>
              <input type="email" id="user_email" name="user_email" />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="4"></textarea>
            </div>
            <div className={classes.btnContainer}>
              <button className={classes.btn} type="submit">
                SEND
              </button>
            </div>
          </form>
        </div>
      </div>
      
    )
}

export default Footer
