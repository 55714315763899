import React, { useRef } from 'react';
import './landingpage.css'
import clickfreight from '../images/click.png'
import { Link } from 'react-router-dom';
import Footer from './Footer/Footer';
import emailjs from '@emailjs/browser';



function LandingPage() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_du7rrwx', 'template_gkjhjfc', form.current, 'EZFxBsFzgZePGBSy-')
      .then((response) => {
        console.log('Email sent successfully:', response, form.current);
        form.current.reset(); // Reset the form fields
        alert('Email sent successfully!'); // Show a success alert message
      })
      .catch((error) => {
        console.error('Email sending error:', error);
        alert('Email sending error. Please try again.'); // Show an error alert message
      });
  
    console.log('working');
  };
  

  return (
    <div className='main-container'>
      <div className="container-fluid">
        <div className="container" style = {{marginTop: '80px' , marginBottom: '80px'}}>
          <Link to="/"><h2 className='logo'><img className='logo-img' src={clickfreight} alt='clickfreight' /></h2></Link> {/* Add this line */}



          <nav>
            <ul>
              <li>
                <Link to="/register"><button className="btn active">Become A Partner</button></Link> {/* Add this line */}
              </li>
            </ul>
          </nav>
        </div>
        <div className="content">
          {/* <h3>Logistic Services</h3> */}
          <h1 style = {{marginBottom: '60px'}}>
            Shipping<br />Made Easy!
          </h1>
          <p style = {{marginBottom: '40px'}}>Qatar's first platform connecting shippers with freight Forwarders.Your cargo is just one click away. Register and start trading globally</p>
          <div style = {{marginBottom: '40px'}}>
            <Link to="/inquiry-form"><button className='btn1'>Request A Quote</button></Link> {/* Add this line */}

          </div>
        </div>
        <Footer mail = {sendEmail} form = {form}/>

      </div>
    </div>
  );
}

export default LandingPage;
